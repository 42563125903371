/*jslint browser: true*/
/*eslint-env browser*/
/*global jQuery*/

jQuery(document).ready(function ($) {
	'use strict';
	
	$(document).foundation();

	$('#gallery-2').lightGallery( {
		thumbnail:true,
		selector: '.gallery-icon a'
	} );

	// slider
	$('#slider .owl-carousel').owlCarousel(
		{
			items: 1,
			loop: true,
			autoplay: true,
			smartSpeed: 1200,
			autoplayHoverPause: true,
			nav: false,
			dots: true,
			navText: ["<i class='fa fa-angle-left' aria-hidden='true'></i>","<i class='fa fa-angle-right' aria-hidden='true'></i>"],
		}
	);

});